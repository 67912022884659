<template>
    <div class="header-right shopping-cart-vue" id="shopping-cart">
        <ul class="nav nav-pills nav-justified">
            <li class="dropdown">
                <a class="dropdown-toggle text-center" 
                   data-toggle="dropdown" href="#">
                    <i class="icon-shopping-cart" style="margin-right:2px"></i>
                    <span class="hidden-xs"> {{ $_locale('cart') }}: </span>
                    {{ $_formatCurrency(total) }} {{ $_CURRENCY_SYMBOL }}
                    <i class="icon-caret-down"></i>
                    <span class="badge badge-primary" 
                          style="top: -10px;margin-left: 0px; position: absolute;border: 1px solid #d0d0d0; background-color:#d0d0d0"
                          >{{ quantity }}</span>
                </a>
                <ul class="dropdown-menu list-group pull-right dropdown-cart">
                    <li class="list-group-item header">
                        <h4 class="list-group-item-heading"><i class="icon-shopping-cart"></i> {{ $_locale('CART') }}</h4>
                        <p class="list-group-item-text"
                           v-if="rows.length === 0"
                           >{{ $_locale('Cart is empty') }}</p>
                    </li>
                    <li >
                        <a v-for="item in filteredRows" v-bind:key="item.cartProductId"
                            @click="selectItem" 
                            class="list-group-item cart-product">
                            {{ item.quantity }} x {{ item.name }}        
                            <span class="glyphicon glyphicon-trash pull-right cart-trash" @click="removeFromCart(item.cartProductId)"></span>
                            <span class="dropdown-cart-price pull-right">{{ $_formatCurrency(item.price) }} {{ $_CURRENCY_SYMBOL }}</span>
                        </a>
                        <a :href="checkoutUrl" 
                           v-if="rows.length > 0 && showGotoCart"
                           class="btn btn-oldstyle-primary" 
                           style="margin: 7px; color:#fff;">
                            <i class="icon-ok-sign"></i> <strong>{{ $_locale('Proceed to checkout') }}</strong>
                        </a>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</template>

<script>
import EcomService from './EcomService.js';

export default {
    data: () => ({
        rows: [],
        hideCartProducts: [],
        showGotoCart: !location.toString().includes('spacheckout'),
        adminMode: false,
    }),
    computed: {
        quantity() {
            const rows = this.filteredRows;
            
            return rows.reduce((acc, curr) => {
                return acc + curr.quantity;
            }, 0)
        },
        total() {
            const rows = this.filteredRows;
            
            return rows.reduce((acc, curr) => {
                return acc + (-(-curr.price) * curr.quantity);
            }, 0)
        },
        filteredRows() {
            return this.rows
                .filter(row => !this.hideCartProducts.includes(row.cartProductId))
        },
        checkoutUrl() {
            const locationParams = new URLSearchParams(window.location.search);
            const urlParams = {
                returnpath: location.pathname
            };
            
            if (this.adminMode) {
                urlParams.forclient = 1;
            }
            
            if (locationParams.get('bareview') == 1) {
                urlParams.bareview = 1;
                urlParams.returnpath += (urlParams.returnpath.indexOf('?') < 0 ? '?' : '&') + 'bareview=1';
            }

            if (locationParams.get('embed') == 1) {
                delete urlParams.returnpath;
            }

            return `${ location.origin }/?action=spacheckout${ Object.keys(urlParams).length > 0 ? '&'+$.param(urlParams) : '' }`;
        },
    },
    methods: {
        selectItem() {
            // doesn't do anything
        },
        async removeFromCart(cartProductId) {
            const { data } = await EcomService.removeFromCart(cartProductId)
            
            if (data.success) {
                this.loadCart();
            } else {
                let errors = data; // might be a string if back end fails

                if (Array.isArray(data.errors)) {
                    errors = data.errors.map((err) => _locale(err.message).ucfirst()).join("\n");
                }

                showErrorDialog(errors, true);
            }
        },
        async loadCart() {
            const { data } = await EcomService.getMyCart()
            
            if (Array.isArray(data.rows)) {
                this.rows = data.rows.filter(Boolean);
                
                $('body').trigger('WnCartUpdate', { rows: this.rows })
            }

            return data;
        },
        getRows() {
            return this.rows;
        },
    },
    created() {
        this.loadCart();
        
        $('body').on(
            'WnCartHideRequest', 
            (evt, data) => {
                this.hideCartProducts.push(data.cartProductId)
            }
        )

        $('body').on(
            'WnCartLoadProducts', 
            (evt) => {
                this.loadCart();
            }
        )

        $('body').on(
            'WnCartSetAdminMode', 
            (evt, { value }) => {
                this.adminMode = value;
            }
        )
    }
}
</script>