<template>
<div class="column-control-root" 
    @click.prevent="$emit('click')">
    <slot></slot>
    <div class="column-controls">
        <span class="icon is-small">
            <i v-if="direction === 'desc' && isActive" class="fa fa-sort-desc"></i>
            <i v-else-if="direction === 'asc' && isActive" class="fa fa-sort-asc"></i>
            <i v-else class="fa fa-sort"></i>
        </span>
    </div>
</div>
</template>

<script>
export default {
    props: {
        isActive: {
            type: Boolean,
            required: false,
            default: false,
        },
        direction: {
            type: String,
            required: false,
            default: null,
        }
    }
}
</script>

<style scoped>
.column-control-root {
    display: inline-flex;
    user-select: none;
    cursor: pointer;
    gap: 7px;
}

.column-controls .fa-sort {
    color: #b3b3b3;
}
</style>