<template>
<div>
    <component :is="tag">
        <template v-for="(item, index) in paginatedItems">
            <slot :item="item" :index="index"></slot>
        </template>
    </component>
    <div class="datatable-footer" v-if="items.length > perPage">
        <div class="pull-right">
            <nav class="">
                <ul class="pagination">
                    <li class="clickable" 
                        v-if="page > 1"
                        @click="changePage(page - 1)">
                        <span aria-hidden="true">«</span>
                    </li>
                    <li class="clickable" 
                        v-for="(pageNum, index) in pages"
                        :key="index"
                        :class="{ 'active': (page == pageNum) }" 
                        @click="changePage(pageNum)" >
                        <span>{{ pageNum }}</span>
                    </li>
                    <li class="clickable" 
                        v-if="page < pages.length"
                        @click="changePage(page + 1)">
                        <span aria-hidden="true">»</span>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: {
        tag: {
            type: String,
            required: false,
            default: 'div',
        },
        items: {
            type: Array,
            required: true,
        },
        perPage: {
            type: Number,
            required: false,
            default: 10,
        },
        defaultPage: {
            type: Number,
            required: false,
            default: null,
        },
        sortTrigger: {
            type: String,
            required: false,
        },
    },
    data: () =>({
        timeStamp: Date.now(),
        page: 1,
        pagingOption: null,
        perPageActive: 10,
    }),
    computed: {
        paginatedItems() {
            // you may think that timeStamp is in no use but Vue will recognize it as a depedency
            // so that when it changes, it triggers a re-render in case of sorting the items array
            // that is also why the sortTrigger setting is used for, when it changes it refreshes
            // the timeStamp and it is also possible to refresh it manually via refenrence method
            const timeStamp = this.timeStamp;
            const items = this.items;
            const page = this.page;
            const perPageActive = this.perPageActive;

            return items.slice((page - 1) * perPageActive, page * perPageActive);
        },
        pages() {
            const items = this.items;
            const page = this.page;
            const perPageActive = this.perPageActive;
            const pagingOption = this.pagingOption;

            if (items.length === 0 || pagingOption === -1) {
                return [1];
            }
            
            const lastPage = Math.ceil(items.length / perPageActive);
            
            return (new Array(lastPage)).fill(null)
                .map((row, index) => {
                    return index + 1;
                })
                .filter((row, index) => {
                    return index == 0 || index == (lastPage - 1) || (index > 0 && Math.abs(index - page) < 3)
                })
        },
    },
    watch: {
        perPage(val) {
            this.perPageActive = val;
        },
        sortTrigger() {
            this.refresh()
        }
    },
    methods: {
        refresh() {
            this.timeStamp = Date.now();
        },
        changePage(value) {
            this.page = value;
        },
    },
    mounted() {
        if (typeof defaultPage === 'number') {
            this.page = this.defaultPage;
        }

        this.perPageActive = this.perPage;
        this.pagingOption = this.perPage;
    }
}
</script>

<style scoped>
.clickable {
    cursor: pointer;
}
</style>