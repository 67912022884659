<template>
<div class="wn-http-container">
    <div class="wn-http-globalspinner" :class="{ show: showGlobalSpinner }"></div>

    <button @click="action"
        class="btn" 
        :class="buttonState"
        :disabled="buttonDisabled">
        <!-- :before as spinner -->
        <i v-if="icon" class="glyphicon" :class="icon"></i>
        <slot></slot>
    </button>
</div>
</template>

<script>
const BUTTON_STATE = {
    idle: 'idle',
    processing: 'processing',
    complete: 'complete',
    error: 'error'
}

export default {
    emits: ['change', 'error', 'success'],
    props: {
        url: {
            type: String,
            required: true,
        },
        method: {
            type: String,
            required: false,
            default: 'GET'
        },
        data: {
            type: Object,
            required: false,
            default: () => ({})
        },
        headers: {
            type: Object,
            required: false,
            default: () => ({})
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        btnClass: {
            type: String,
            required: false,
            default: 'btn-default'
        },
        icon: {
            type: String,
            required: false,
            default: ''
        },
        spinner: {
            type: Boolean,
            required: false,
            default: false
        },
        successMessage: {
            type: String,
            required: false,
            default: ''
        },
        preventAlert: {
            type: Boolean,
            required: false,
            default: false
        },
    },
    data: () => ({
        BUTTON_STATE,
        buttonState: BUTTON_STATE.idle,
    }),
    computed: {
        showGlobalSpinner() {
            const isProcessing = this.buttonState === BUTTON_STATE.processing

            return this.spinner && isProcessing
        },
        buttonDisabled() {
            if (this.disabled) {
                return true
            }

            return this.buttonState !== BUTTON_STATE.idle
        },
        buttonState() {
            const isProcessing = this.buttonState === BUTTON_STATE.processing
            const btnClass = this.btnClass
            
            let classes = [ btnClass ]

            if (isProcessing) {
                classes.push('button-processing')
            }

            return classes
        },
    },
    methods: {
        async action() {
            this.buttonState = BUTTON_STATE.processing
            this.$emit('change', this.buttonState)

            let success = false
            let error = this.$_locale('internal server error')
            let successMessage = this.successMessage || this.$_locale('success')
            
            try {
                const { data } = await this.get()

                if (data.success) {
                    success = true
                    
                    this.$emit('success', data)
                } else {                    
                    if (Array.isArray(data.errors)) {
                        error = data.errors.map((err) => this.$_locale(err.message)).join(' ')
                    }
                }
            } catch(e) {
                console.error(e)
            }

            if (success) {
                if (!this.preventAlert) {
                    this.$_swal({
                        title: successMessage,
                        icon: 'success',
                        confirmButtonText: 'OK',
                    })
                }                

                this.$emit('change', BUTTON_STATE.complete)
            } else {
                if (!this.preventAlert) {
                    this.$_swal({
                        title: error,
                        icon: 'error',
                        confirmButtonText: 'OK',
                    })
                }                

                this.$emit('change', BUTTON_STATE.error)
                this.$emit('error', error)
            }

            this.buttonState = BUTTON_STATE.idle
        },
        get() {
            const options = {
                url: this.url,
                method: this.method
            }

            if (this.data) {
                options.data = this.data
            }

            if (this.headers) {
                options.headers = this.headers
            }

            return this.$_http(options)
        },
    },
}
</script>