
<div class="wn-http-container">
    <div class="wn-http-globalspinner" :class="{ show: showGlobalSpinner }"></div>

    <button @click="action"
        class="btn" 
        :class="buttonState"
        :disabled="buttonDisabled">
        <!-- :before as spinner -->
        <i v-if="icon" class="glyphicon" :class="icon"></i>
        <slot></slot>
    </button>
</div>
