import Swal from 'sweetalert2'
import '@sweetalert2/theme-bootstrap-4/bootstrap-4.scss';
import './sweet-alert-hack.scss';

import { 
    mixinsFactory,
    mixinsHelp
} from './TablePagesMixins'

import CommonService from './CommonService.js'
import EcomService from './EcomService.js'
import WnModal from './WnModal.vue'
import WnDialog from './WnDialog.vue'
import WnSelect from './WnSelect.vue'
import WnCart from './EcomCartWidget.vue'
import WnPages from './WnPages.vue'
import WnTablePages from './WnTablePages.vue'
import WnTableHeaderCell from './WnTableHeaderCell.vue'
import WnTableColvis from './WnTableColvis.vue'
import WnDropDown from './WnDropDown.vue'
import WnDatePicker from './WnDatePicker.vue'
import WnEditor from './WnEditor.vue'
import WnHttp from './WnHttp.vue'
import WnChoices from './WnChoices.vue'
import { formatCurrency } from '../common/formatCurrency';

const http = angular.injector(["ng"]).get("$http");
const { formatDateTime, dateFormat, getDateFormat, CURRENCY_SYMBOL } = window.wisenetwork

const defaultSwalMixin = Swal.mixin({
    customClass: {
        htmlContainer: '',
        container: 'wise-sweet-alert',
        confirmButton: 'btn btn-lg btn-success',
        cancelButton: 'btn btn-lg btn-default',
        denyButton: 'btn btn-lg btn-default',
    },
    buttonsStyling: false,
    reverseButtons: true,
})

/**
 * Some mapping provided from old swal options to new format.
 *  
 * @param {Object} options 
 * @returns {Promise}
 */
function swalFire(options) {
    /*
    if (options.confirmButtonClass) {
        options.customClass.confirmButton = 'btn ' + options.confirmButtonClass;
    }

    if (options.cancelButtonClass) {
        options.customClass.cancelButton = 'btn ' + options.cancelButtonClass;
    }
    */
    return defaultSwalMixin.fire({
        ...options,
    })
}

const components = {
    WnModal,
    WnDialog,
    WnSelect,
    WnCart,
    WnPages,
    WnTablePages,
    WnTableHeaderCell,
    WnTableColvis,
    WnDropDown,
    WnDatePicker,
    WnEditor,
    WnHttp,
    WnChoices,
}

export default {
    install(Vue, context) {
        Vue.prototype.$_locale = (placeholder, firstUpper = true, injection = null) => {
            const translation = window._locale(placeholder, injection);
            
            if (firstUpper) {
                return translation.ucfirst();
            }
            
            return translation;
        };
        Vue.prototype.$_swal = swalFire;
        Vue.prototype.$_formatCurrency = formatCurrency;
        Vue.prototype.$_CURRENCY_SYMBOL = CURRENCY_SYMBOL;
        Vue.prototype.$_formatDateTime = formatDateTime;
        Vue.prototype.$_dateFormat = dateFormat;
        Vue.prototype.$_getDateFormat = getDateFormat;
        Vue.prototype.$_localeDates = window._localeDates;
        Vue.prototype.$_http = http;
        Vue.prototype.$_CommonService = CommonService;
        Vue.prototype.$_EcomService = EcomService;
        
        Object.keys(components).forEach((name) => {
            const component = components[name];
    
            Vue.component(name, component);    
        })
        
        if (typeof context !== 'undefined') {
            //store.registerModule('common', CommonStore)
        }
    },
    mixinsFactory,
    mixinsHelp,
}