
<select class="wn-choices"
    ref="main"
    :value="selectedValue"
    @change="handleValue"
    :multiple="multiple">
    <option value="">{{ placeholder || $_locale('choicesjs.noValue') }}</option>
    <option 
        v-for="option in options" 
        :key="option.id" 
        :value="option.id">
        {{ option.text }}
    </option>
</select>
