<template>
    <div class="modal" tabindex="-1" ref="master">
        <div class="modal-dialog" :class="[modalClass]">
            <div class="modal-content">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
// IMPORTANT! This is Bootstrap 5 version! WnModal is for older BS3
export default {
    props: {
        modalClass: {
            type: String,
            required: false,
        },
        config: {
            type: Object,
            required: false,
            default: () => ({})
        },
    },
    data: () => ({
        instance: null,
    }),
    methods: {
        open() {
            this.instance.show()
        },
        close() {
            this.instance.hide()
        }
    },
    mounted() {
        if (typeof window.bootstrap === 'undefined') {
            return console.log('Bootstrap 5 JS not loaded')
        }

        const master = this.$refs.master;
        
        this.instance = new bootstrap.Modal(master, this.config)

        master.addEventListener('hidden.bs.modal', (evt) => {
            this.$emit('hidden');
        })

        master.addEventListener('shown.bs.modal', (evt) => {
            this.$emit('shown');
        })
    }
}
</script>
