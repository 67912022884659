
<div class="wn-dropdown">
    <div class="wn-dropdown__button" @click="toggleShow($event)">
        <slot name="button"></slot>
    </div>
    <div class="wn-dropdown__backdrop" v-if="show" @click="show = false;"></div>
    <div class="wn-dropdown__base" 
        ref="base"
        v-show="show"
        @click="hideOnSelect">
        <slot name="options"></slot>
    </div>
</div>
