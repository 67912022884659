
<div class="column-control-root" 
    @click.prevent="$emit('click')">
    <slot></slot>
    <div class="column-controls">
        <span class="icon is-small">
            <i v-if="direction === 'desc' && isActive" class="fa fa-sort-desc"></i>
            <i v-else-if="direction === 'asc' && isActive" class="fa fa-sort-asc"></i>
            <i v-else class="fa fa-sort"></i>
        </span>
    </div>
</div>
