
<div class="dataTables_wrapper wn-datatable">
    <div class="table-responsive">
        <table class="table table-bordered table-hover table-striped table-condensed no-footer" 
                style="border-top: 1px solid #ddd;margin-bottom:0px;">
            <thead>
                <tr v-show="!!$slots.filters">
                    <slot name="filters"></slot>
                </tr>
                <tr>
                    <slot name="head"></slot>
                </tr>
            </thead>
            <tbody>
            <template v-for="(item, index) in paginatedItems">
                <slot :item="item" :index="index"></slot>
            </template>
            <!--
            <tr v-for="(item, index) in paginatedItems" 
                :key="keyName !== null ? item[keyName] : index"
                ="bindRow(item, index)">
                <slot :item="item"></slot>
            </tr>
            -->
            <tr v-show="items.length === 0">
                <slot name="empty"></slot>
            </tr>
            <slot name="footer"></slot>
            </tbody>
        </table>
    </div>
    <div class="datatable-footer" >
        <div class="pull-left" style="display:inline-flex;">
            <div class="pagination" style="line-height: 36px;">
                {{ $_locale('showing hits') }}
                {{ pageFirst }} - {{ pageLast }}
                ({{ $_locale('total', false) }} {{ items.length }})
            </div>
        </div>
        <div class="pull-right" style="display:inline-flex;">
            <select
                class="form-control pagination" 
                style="width: auto; margin-right: 20px;"
                :value="pagingOption"
                @input="onPagingChange">
                <option v-for="opt in pagingOptions" 
                    :key="opt" 
                    :value="opt">{{ opt > -1 ? opt : $_locale('all') }}</option>
            </select>
            <nav class="">
                <ul class="pagination">
                    <li class="clickable" 
                        v-if="page > 1"
                        @click="changePage(page - 1)">
                        <span aria-hidden="true">«</span>
                    </li>
                    <li class="clickable" 
                        v-for="(pageNum, index) in pages"
                        :key="index"
                        :class="{ 'active': (page == pageNum) }" 
                        @click="changePage(pageNum)" >
                        <span>{{ pageNum }}</span>
                    </li>
                    <li class="clickable" 
                        v-if="page < pages.length"
                        @click="changePage(page + 1)">
                        <span aria-hidden="true">»</span>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</div>
