
<div>
    <component :is="tag">
        <template v-for="(item, index) in paginatedItems">
            <slot :item="item" :index="index"></slot>
        </template>
    </component>
    <div class="datatable-footer" v-if="items.length > perPage">
        <div class="pull-right">
            <nav class="">
                <ul class="pagination">
                    <li class="clickable" 
                        v-if="page > 1"
                        @click="changePage(page - 1)">
                        <span aria-hidden="true">«</span>
                    </li>
                    <li class="clickable" 
                        v-for="(pageNum, index) in pages"
                        :key="index"
                        :class="{ 'active': (page == pageNum) }" 
                        @click="changePage(pageNum)" >
                        <span>{{ pageNum }}</span>
                    </li>
                    <li class="clickable" 
                        v-if="page < pages.length"
                        @click="changePage(page + 1)">
                        <span aria-hidden="true">»</span>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</div>
