<template>
<div style="position: relative;">
    <div @click="setVisible(false)" 
        v-if="visible"
        class="colvis-backdrop"></div>
    <button class="pull-right btn btn-default"
        @click="setVisible(true)">
        <span class="glyphicon glyphicon-cog"></span>
    </button>
    <ul v-if="visible" 
        class="colvis-menu">
        <li v-for="(value, key) in colvis">
            <input type="checkbox" :id="'colvis-'+key"
                :checked="value === true"
                @change="colvisToggle(key, !value)">
            <label :for="'colvis-'+key">{{ headers[key] }}</label>
        </li>
    </ul>
</div>
</template>

<script>
export default {
    props: {
        value: {
            type: Object,
            required: true,
        },
        headers: {
            type: Object,
            required: true,
        },
        parent: {
            type:  String,
            required: false,
            default: '',
        },
    },
    data: () => ({
        visible: false,
        styleEl: null,
    }),
    computed: {
        colvis: {
            get () {
                return this.value
            },
            set (newVal) {
                this.$emit('input', newVal)
            }
        },
    },
    methods: {
        setVisible(value = null) {
            this.visible = value;
        },
        colvisToggle(key, value) {
            this.colvis[key] = value;

            this.generateCSS()
        },
        generateCSS() {
            const parent = this.parent.length > 0 ? this.parent+' ' : '';
            const hidden = Object.keys(this.colvis)
                .filter(key => this.colvis[key] === false)
            const targets = hidden.map(key => `${ parent }td[col="${ key }"], ${ parent }th[col="${ key }"]`).join(', ')
            const css = `${ targets } { display: none; }`

            this.styleEl.innerHTML = css;
        },
    },
    mounted() {
        const style = document.createElement('style')

        document.body.appendChild(style)

        this.styleEl = style;
        this.generateCSS()
    },
}
</script>

<style scoped>
.colvis-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(.4,.4,.4,.5);
    z-index: 999;
}

.colvis-menu {
    position: absolute;
    right: 0;
    top: 26px;
    background: white;
    z-index: 999;
    width: 200px;
    border: 1px solid lightgrey;
    border-radius: 2px;
    box-shadow: 2px 2px 5px grey;
    padding: 10px 14px;
}
.colvis-menu li {
    /*display: block;*/
    list-style: none;
    display: flex;
    gap: 14px;
    padding-bottom: 7px;
}

.colvis-menu li input {
    margin: 0;
}

.colvis-menu li label {
    margin: 0;
}
</style>