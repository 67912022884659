
<div class="header-right shopping-cart-vue" id="shopping-cart">
    <ul class="nav nav-pills nav-justified">
        <li class="dropdown">
            <a class="dropdown-toggle text-center" 
               data-toggle="dropdown" href="#">
                <i class="icon-shopping-cart" style="margin-right:2px"></i>
                <span class="hidden-xs"> {{ $_locale('cart') }}: </span>
                {{ $_formatCurrency(total) }} {{ $_CURRENCY_SYMBOL }}
                <i class="icon-caret-down"></i>
                <span class="badge badge-primary" 
                      style="top: -10px;margin-left: 0px; position: absolute;border: 1px solid #d0d0d0; background-color:#d0d0d0"
                      >{{ quantity }}</span>
            </a>
            <ul class="dropdown-menu list-group pull-right dropdown-cart">
                <li class="list-group-item header">
                    <h4 class="list-group-item-heading"><i class="icon-shopping-cart"></i> {{ $_locale('CART') }}</h4>
                    <p class="list-group-item-text"
                       v-if="rows.length === 0"
                       >{{ $_locale('Cart is empty') }}</p>
                </li>
                <li >
                    <a v-for="item in filteredRows" v-bind:key="item.cartProductId"
                        @click="selectItem" 
                        class="list-group-item cart-product">
                        {{ item.quantity }} x {{ item.name }}        
                        <span class="glyphicon glyphicon-trash pull-right cart-trash" @click="removeFromCart(item.cartProductId)"></span>
                        <span class="dropdown-cart-price pull-right">{{ $_formatCurrency(item.price) }} {{ $_CURRENCY_SYMBOL }}</span>
                    </a>
                    <a :href="checkoutUrl" 
                       v-if="rows.length > 0 && showGotoCart"
                       class="btn btn-oldstyle-primary" 
                       style="margin: 7px; color:#fff;">
                        <i class="icon-ok-sign"></i> <strong>{{ $_locale('Proceed to checkout') }}</strong>
                    </a>
                </li>
            </ul>
        </li>
    </ul>
</div>
