<template>
    <textarea ref="input"></textarea>
</template>

<script>
export default {
    emits: ['input'],
    props: {
        value: {
            type: String,
            required: true,
        },
        options: {
            type: Object,
            required: true,
            default: () => ({}),
        },
    },
    methods: {
        init() {
            const CKEDITOR = window.CKEDITOR;
            const el = this.$refs.input;

            const instance = CKEDITOR.replace(el, this.options);
            instance.setData(this.value);

            instance.on('change', () => { 
                this.$emit('input', instance.getData())
            });
        }
    },
    mounted() {
        this.init()
    }
}
</script>