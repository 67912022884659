// using angularjs http for now
// otherwise maybe axios...?
const http = angular.injector(["ng"]).get("$http");
const { baseUrl = '' } = window.wisenetwork;

export default {
        /**
         * Returns an object that can be used sending a transactional email
         *
         * @return object
         */
        getEmailObject() {
            return {
                from: '',
                recipients: [],
                subject: '',
                html: '',
                plainText: '',
                cc: [],
                bcc: []
            };
        },

        /**
         * Sends email object to the sendtransactionalemail endpoint 
         *
         * @param object email
         * @param function callback
         * @returns Promise 
         */
        sendEmail(email) {
            return http({
                method: 'POST',
                url: baseUrl+'?action=sendtransactionalemail',
                data: { 
                    emailArgs: email 
                }
            })
        },

        getPeople() {
            return http({
                method: 'GET',
                url: baseUrl+'?action=experimentalpeoplelisterajax'
            })
        },
        
        /**
         * For ui.select ajax
         *
         * @param string search
         * @param function callback
         * @returns Promise 
         */
        searchPeople(search, companyIds = null) {
            var data = {
                searchTerm: search,
                method: 'AllForSelect'
            };

            if (!!companyIds) {
                data.method = 'InCompanies';
                data.companyIds = companyIds;
            }

            return http({
                method: 'POST',
                url: baseUrl+'?action=listpersonsajax',
                data: data
            })
        },
        
        /**
         * @param string search
         * @returns Promise 
         */
        searchCompanies(search) {
            return http({
                method: 'POST',
                url: baseUrl+'?action=listcompaniesajax',
                data: {
                    method: 'AllForNewOrderSelectBox2',
                    searchTerm: search
                }
            })
        },

        getArticles(keywords) {
            return http({
                url: baseUrl+'/?action=searcharticles&keywords=' + keywords,
                method: 'GET'
            })
        },

        /**
         * For ui.select ajax
         * 
         * @param function callback
         */
        getCategories() {
            return http({
                url: baseUrl+'/?action=parsecategory',
                method: 'GET'
            })
        },

        listTags(objectId, objectTypeName) {
            return http({
                url: baseUrl+'/?action=listtags',
                method: 'POST',
                data: {
                    objectId: objectId, 
                    objectTypeName: objectTypeName
                }
            })
        },

        getEvents(settings) {
            return http({
                url: baseUrl+'/?controller=ajax&events=1&'+$.param(settings),
                method: 'GET'
            })
        }
    }