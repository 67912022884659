<template>
<select class="wn-choices"
    ref="main"
    :value="selectedValue"
    @change="handleValue"
    :multiple="multiple">
    <option value="">{{ placeholder || $_locale('choicesjs.noValue') }}</option>
    <option 
        v-for="option in options" 
        :key="option.id" 
        :value="option.id">
        {{ option.text }}
    </option>
</select>
</template>

<script>
export default {
    emits: ['input'],
    props: {
        options: {
            type: Array,
            required: true,
            default: () => ([]),
        },
        value: {
            required: false,
        },
        multiple: {
            type: Boolean,
            reguired: false,
            default: false,
        },
        settings: {
            type: Object,
            required: false,
            default: () => ({}),
        },
        placeholder: {
            type: String,
            required: false,
        },
    },
    data: () => ({
        instance: null,
        selectedValue: null,
    }),
    watch: {
        options: {
            immediate: true,
            deep: true,
            handler() {
                this.$nextTick(() => this.init())
            }
        },
        /*
        value(newVal) {
            this.select2El.select2('data', newVal).trigger('change')
        },
        */
    },
    methods: {
        init() {
            if (!window.Choices) {
                console.warn('ChoicesJS not loaded!')
                return;
            }
            const { main } = this.$refs
            const choicesSettings = {
                shouldSort: false,
                allowHTML: true,
                noResultsText: this.$_locale('choicesjs.noResultsText'),
                noChoicesText: this.$_locale('choicesjs.noChoicesText'),
                itemSelectText: this.$_locale('choicesjs.itemSelectText'),
            }

            if (this.settings) {
                for (let key of Object.keys(this.settings)) {
                    choicesSettings[key] = this.settings[key]
                }
            }
            
            if (this.options.length > 0) {
                this.instance = new window.Choices(main, choicesSettings)
            }               
        },
        handleValue($event) {
            if (this.multiple) {
                const selectedOptions = Array.from(
                    $event.target.selectedOptions
                ).map(option => {
                    const [ selected ] = this.options.filter(row => row.id == option.value)

                    return selected
                })
                
                this.$emit('input', selectedOptions)
                return;
            }
            
            this.selectedValue = $event.target.value

            const [ selected ] = this.options.filter(row => row.id === this.selectedValue)

            this.$emit('input', selected || null)
        },
        getInstance() {
            return this.instance
        },
    },
    mounted() {
        if (this.value?.id) {
            this.selectedValue = this.value.id
        }

        this.$nextTick(() => this.init())
    },
}
</script>