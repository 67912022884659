
<div :class="{
    'input-group': hasInputGroup,
}">
    <input type="text" 
        ref="dateInput"
        class="form-control" />
    <span class="input-group-btn" v-if="hasInputGroup">
        <button class="btn btn-default" @click="resetDatePicker">
            <i class="glyphicon glyphicon-remove"></i>
        </button>
    </span>
</div>
