<template>
    <div class="modal fade" tabindex="-1" role="dialog" ref="master">
        <div class="modal-dialog" :class="[modalClass]">
            <div class="modal-content">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        modalClass: {
            type: String,
            required: false,
        },
        backdrop: {
            type: [String, Boolean],
            default: true, // Set default to true, or 'static' for non-closing overlay
        },
        keyboard: {
            type: Boolean,
            default: true, // Set default to true to allow closing with ESC key
        }
    },
    methods: {
        open() {
            const master = this.$refs.master;
            
            $(master).modal({
                backdrop: this.backdrop,
                keyboard: this.keyboard,
                show: true
            })
        },
        close() {
            const master = this.$refs.master;
            
            $(master).modal('hide');
        }
    },
    mounted() {
        const master = this.$refs.master;
        
        $(master).on('hidden.bs.modal', () => {
            this.$emit('close')
        })
    }
}
</script>