
<div style="position: relative;">
    <div @click="setVisible(false)" 
        v-if="visible"
        class="colvis-backdrop"></div>
    <button class="pull-right btn btn-default"
        @click="setVisible(true)">
        <span class="glyphicon glyphicon-cog"></span>
    </button>
    <ul v-if="visible" 
        class="colvis-menu">
        <li v-for="(value, key) in colvis">
            <input type="checkbox" :id="'colvis-'+key"
                :checked="value === true"
                @change="colvisToggle(key, !value)">
            <label :for="'colvis-'+key">{{ headers[key] }}</label>
        </li>
    </ul>
</div>
