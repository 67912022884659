<template>
    <input type="text" class="form-control" />
</template>

<script>
    export default {
        props: {
            name: '',
            placeholder: '',
            options: {
                type: Array,
                default: () => [],
            },
            value: null,
            multiple: {
                type: Boolean,
                default: false
            },
            allowClear: {
                type: Boolean,
                default: false
            },
            tagging: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
            }
        },
        watch: {
            options: {
                immediate: true,
                deep: true,
                handler() {
                    this.init()
                }
            },
            /*
            value(newVal) {
                this.select2El.select2('data', newVal).trigger('change')
            },
            */
        },
        mounted() {
            this.init()
        },
        methods: {
            init() {
                const select = $(this.$el);
                const settings = {
                    placeholder: this.placeholder ? this.placeholder : window._locale('select').ucfirst(),
                    theme: 'bootstrap',
                    width: '100%',
                    allowClear: this.allowClear,
                }
                
                if (this.tagging) {
                    settings.tags = this.options;
                    settings.tokenSeparators = ['|']
                } else {
                    settings.multiple = this.multiple;
                    settings.data = this.options;
                }
                
                select.select2(settings)
                .val(Array.isArray(this.value) ? this.value.map(row => row.id) : '')
                .trigger('change')
                .on('change', () => {
                    this.$emit('input', select.select2('data'))
                })
            },
        },
        destroyed: function () {
            $(this.$el).off().select2('destroy')
        }
    }
</script>
